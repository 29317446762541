import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../assets/images/logo.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/logout.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';
import ZPLogoGroup from '../../assets/images/zen-logo.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getUserInitials } from '../../helpers/utils';

const UserHeader = ({ fromFormDetails = false }) => {
  const navigate = useNavigate();
  const { setModal } = useContext(OrganisationContext);

  const { user: userData, profileData } = useSelector(state => state.user);
  const { user } = userData || {};

  const [showuserMenu, setShowuserMenu] = useState(false);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowuserMenu(false);
    }
  };

  const onLogout = e => {
    if (fromFormDetails) {
      setModal({
        type: 'leave-page',
        content: {
          onConfirm: () => {
            localStorage.removeItem('user');
            navigate('/login', { replace: true });
          },
        },
      });
    } else {
      localStorage.removeItem('user');
      navigate('/login', { replace: true });
    }
  };

  const onLogoClick = () => {
    if (fromFormDetails) {
      setModal({ type: 'leave-page', content: { onConfirm: () => navigate('/forms') } });
    }
  };

  const onMyProfileClick = () => {
    if (fromFormDetails) {
      setModal({ type: 'leave-page', content: { onConfirm: () => navigate('/profile') } });
    } else {
      navigate('/profile');
    }
  };
  return (
    <UserHeaderWrapper className="flex items-center w-full border-bottom pxy-3">
      <div className="flex flex-1 items-center px-6 logo-wrapper">
        <picture>
          <source srcSet={ZPLogoGroup} media="(min-width: 786px)" width={116} height={40} />
          <img onClick={onLogoClick} className="logo cursor" height={24} src={Logo} alt="example" />
        </picture>
      </div>
      <div
        className="flex items-center col-gap-1 border-left px-4 h-full cursor"
        onClick={() => setShowuserMenu(!showuserMenu)}
        onBlur={onBlur}
        tabIndex={0}>
        <div className="flex-column items-end user-text-wrapper">
          <span className="medium-text one-line txt-user-name w-full">{`${user?.forename} ${user?.surname}`}</span>
          {(userData?.organizations || []).length && userData?.organizations[0]?.badge_number ? (
            <span className="regular-text one-line txt-user w-full">{userData?.organizations[0]?.badge_number}</span>
          ) : (
            <></>
          )}
        </div>
        {profileData?.avatar?.url || user?.avatar_url ? (
          <div className="flex items-center justify-center">
            <img
              className="user-profile"
              src={user?.avatar_url ? user?.avatar_url : profileData?.avatar?.url}
              alt="user-profile"
              width={48}
              height={48}
            />
          </div>
        ) : (
          <div
            className="user-icon flex items-center justify-center empty-img nots-user-image radius-4  border"
            style={{ width: '48px', height: '48px' }}>
            <span className="font-20 bold-text grey-text">{getUserInitials(user || {})}</span>
          </div>
        )}
        <MenuIcon />
        {showuserMenu && (
          <div className="absolute card user-menu">
            <div className="flex items-center cursor menu-item" onClick={onMyProfileClick}>
              <UserIcon className="action-icon" />
              <span className="regular-text ml-2 item-text">My Profile</span>
            </div>
            <div className="flex items-center cursor menu-item" onClick={onLogout}>
              <LogoutIcon className="action-icon" />
              <span className="regular-text ml-2 item-text">Log Out</span>
            </div>
          </div>
        )}
      </div>
    </UserHeaderWrapper>
  );
};

const UserHeaderWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColor};
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 220px;
  position: sticky;
  top: 0;
  z-index: 1;

  .user-profile {
    border-radius: 16px;
  }

  .user-text-wrapper {
    min-width: 125px;
    max-width: calc(100% - 64px);
  }
  .txt-user {
    text-align: end;
    width: calc(100% - 5px);
    max-width: calc(100% - 5px);
  }

  .user-menu {
    position: absolute;
    width: auto;
    bottom: -85px;
    right: 15px;
    min-width: 210px;
    padding: 8px;
    z-index: 10;
    border-radius: 12px;

    .menu-item {
      padding: 8px;
      border-radius: 8px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.backgroundColor};

        .item-text {
          color: ${({ theme }) => theme.colors.ZenPurple};
        }

        .action-icon {
          path {
            fill: ${({ theme }) => theme.colors.ZenPurple};
          }
        }
      }
    }
  }
  .empty-img nots-user-image {
    background: ${({ theme }) => theme?.colors?.ZenGray3};
  }

  @media only screen and (max-width: 768px) {
    .logo {
      height: 32px;
    }
    .txt-user-name {
      text-align: end;
      width: calc(100% - 5px);
      max-width: calc(100% - 5px);
    }
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: minmax(60px, 60px) auto;
    .logo-wrapper {
      padding: 0;
    }
  }
`;

export default UserHeader;
