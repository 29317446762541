import React, { Fragment, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ImagePlaceholder from '../../assets/images/image-placeholder.svg';
import Button from '../../components/Elements/button/button';
import Loader from '../../components/Elements/loader';
import UserHeader from '../../components/UserHeader';
import { getFormsList } from '../../store/features/formSlice';
import { FormsWrapper } from '../../styles/pages/forms.styled';

const Forms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formsList } = useSelector(state => state.forms);
  const pageRef = useRef(0);
  const [loading, setLoading] = useState(true);

  const getFormList = (page, merge) => {
    dispatch(
      getFormsList({
        params: { page: page, size: 15, version_status: 'ACTIVE', status: 'ACTIVE' },
        merge,
      }),
    )
      .then(() => {
        pageRef.current = page;
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getFormList(0, false);
  }, []);

  const fetchMoreData = () => {
    getFormList(pageRef.current + 1, true);
  };

  const onLaunchForm = form => {
    navigate(`/forms/${form.id}`);
  };

  return (
    <FormsWrapper className="flex-column w-full">
      <UserHeader />
      <div className="flex-column flex-1">
        {loading ? (
          <Loader className="w-full" />
        ) : (
          <div className="flex-column flex-1 overflow-scroll w-full">
            <InfiniteScroll
              className="flex-column flex-1"
              dataLength={formsList?.content.length || 0}
              hasMore={!formsList?.last}
              loader={
                <div className="item flex items-center justify-center">
                  <Loader className="w-full" />
                </div>
              }
              next={fetchMoreData}>
              {formsList?.content?.map(form => (
                <Fragment>
                  <div className="tablet-view py-6 px-10 border-bottom">
                    <div className="pr-4">
                      <img
                        src={form.prizedraw?.image?.url || ImagePlaceholder}
                        alt="TextImage"
                        width={67}
                        height={67}
                        className="radius-4"
                      />
                    </div>
                    <div className="flex-column flex-1 row-gap-2">
                      <label className="font-16 medium-text">{form?.prizedraw_client?.organization?.name}</label>
                      <label className="regular-text lighter-text">{form?.prizedraw?.name}</label>
                      <p className="regular-text grey-text">{form.description}</p>
                      <Button
                        label="Launch form"
                        className="primary mt-4"
                        borderRadius="16px"
                        size="large"
                        onClick={() => onLaunchForm(form)}
                      />
                    </div>
                  </div>
                  <div className="form-item w-full border-bottom desktop-view" key={form.id}>
                    <div className="border-right flex items-center px-6 font-16 medium-text">
                      {form?.prizedraw_client?.organization?.name}
                    </div>
                    <div className="flex border-right px-6">
                      <img
                        src={form.prizedraw?.image?.url || ImagePlaceholder}
                        alt="TextImage"
                        width={67}
                        height={67}
                        className="mr-3 radius-4"
                      />
                      <div className="flex-column justify-center one-line">
                        <label className="medium-text font-16 one-line lighter-text">{form?.prizedraw?.name}</label>
                      </div>
                    </div>
                    <div className="flex items-center border-right px-6 font-14">
                      <p className="regular-text grey-text two-lines">{form.description}</p>
                    </div>
                    <div className="px-6 flex items-center">
                      <Button
                        label="Launch form"
                        className="primary"
                        borderRadius="16px"
                        size="large"
                        onClick={() => onLaunchForm(form)}
                      />
                    </div>
                  </div>
                </Fragment>
              ))}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </FormsWrapper>
  );
};

export default Forms;
