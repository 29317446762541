import { createGlobalStyle, css } from 'styled-components';

const text = css`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.RegularText};
`;

export const CommonStyleWrapper = createGlobalStyle`
  * :focus-visible {
    outline: none;
  }

  .card {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
  }

  .light-text {
    ${text}
    font-family: Poppins Light;
  }

  .regular-text {
    ${text}
    font-family: Poppins Regular;
  }

  .normal-text {
    ${text}
    font-weight: 500;
    font-family: Poppins Regular;
  }

  .medium-text {
    ${text}
    font-family: Poppins Medium;
  }

  .bold-text {
    ${text}
    font-family: Poppins Bold;
  }

  .semibold-text {
    ${text}
    font-family: Poppins Semibold;
  }

  .black-text {
    ${text}
    font-family: Poppins Black;
  }

  .lighter-text {
    color: ${({ theme }) => theme.colors.LighterText};
  }

  .gray-500-text {
    color: ${({ theme }) => theme.colors.Gray500};
  }

  .white-text {
    color: #ffffff;
  }

  .error-text,
  .required-star {
    color: ${({ theme }) => theme.colors.ZenNegative};
  }

  .color-purple {
    color: ${({ theme }) => theme.colors.ZenPurple};
  }

  .input {
    height: 48px;
    border: 1px solid rgba(181, 185, 193, 0.3);
    border-radius: 16px;
    color: #053149;
    font-family: Poppins Regular;
    font-size: 16px;
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
  }

  .input:focus {
    border: 1px solid ${({ theme }) => theme.colors.ZenPurple};
  }

  .input::placeholder {
    opacity: 0.3;
  }

  .loader {
    display: flex;
    position: absolute;
    align-items: center;
    background: #05314933;
    border-radius: 16px;
    justify-content: center;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }


  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .flex {
    display: flex;
  }

  .display-none{
    display: none;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .items-center {
    align-items: center;
  }

  .items-baseline {
    align-items: baseline;
  }

  .items-end {
    align-items: flex-end;
  }

  .items-start {
    align-items: flex-start;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    border: none;
    margin-bottom: 15px;
  }

  p {
    // padding: 0px;
    margin: 0px;
  }

  .flex-1 {
    flex: 1;
  }

  .col-gap-1 {
    column-gap: 4px;
  }

  .col-gap-2 {
    column-gap: 8px;
  }

  .col-gap-4 {
    column-gap: 16px;
  }

  .col-gap-6 {
    column-gap: 24px;
  }

  .col-gap-10 {
    column-gap: 40px;
  }

  .row-gap-1 {
    row-gap: 4px;
  }

  .row-gap-2 {
    row-gap: 8px;
  }

  .row-gap-4 {
    row-gap: 16px;
  }


  .ml-4 {
    margin-left: 16px;
  }

  .ml-5 {
    margin-left: 20px;
  }

  .ml-6 {
    margin-left: 24px;
  }

  .ml-8 {
    margin-left: 32px;
  }

  .ml-10 {
    margin-left: 40px;
  }

  .ml-12 {
    margin-left: 48px;
  }

  .mr-auto {
    margin-right: auto;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mr-6 {
    margin-right: 24px;
  }

  .mr-5 {
    margin-right: 20px;
  }

  .mr-10 {
    margin-right: 40px;
  }

  .mr-12 {
    margin-right: 48px;
  }


  .mr--16 {
    margin-right: 64px;
  }

  .mr-8 {
    margin-right: 32px;
  }

  .mr-7 {
    margin-right: 28px;
  }

  .mr-4 {
    margin-right: 16px;
  }

  .ml-2 {
    margin-left: 8px;
  }

  .ml-1 {
    margin-left: 4px;
  }

  .ml-3 {
    margin-left: 12px;
  }

  .ml-4 {
    margin-left: 16px;
  }

  .ml-6 {
    margin-left: 24px;
  }

  .mr-1 {
    margin-right: 4px;
  }

  .mr-2 {
    margin-right: 8px;
  }

  .mr-3 {
    margin-right: 12px;
  }

  .mr-16 {
    margin-right: 16px;
  }

  .mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }

  .mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  .mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }

  .mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }

  .mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mxy-6 {
    margin: 24px;
  }

  .mxy-2 {
    margin: 8px;
  }

  .mxy-3 {
    margin: 12px;
  }

  .mxy-05 {
    margin: 2px;
  }

  .mt-1 {
    margin-top: 4px;
  }

  .mt-3 {
    margin-top: 12px;
  }

  .mt-5 {
    margin-top: 20px;
  }

  .mt-6 {
    margin-top: 24px;
  }

  .mt-7 {
    margin-top: 28px;
  }

  .mt-2 {
    margin-top: 8px;
  }

  .mt-4 {
    margin-top: 16px;
  }

  .mt-8 {
    margin-top: 32px;
  }

  .mt-10 {
    margin-top: 40px;
  }
  .mt-11 {
    margin-top: 42px;
  }
  .mt-12 {
    margin-top: 48px;
  }

  .mt-16 {
    margin-top: 64px;
  }

  .mt-20 {
    margin-top: 80px;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 4px;
  }

  .mb-2 {
    margin-bottom: 8px;
  }

  .mb-3 {
    margin-bottom: 12px;
  }

  .mb-4 {
    margin-bottom: 16px;
  }

  .mb-6 {
    margin-bottom: 24px;
  }

  .mb-8 {
    margin-bottom: 32px;
  }

  .mb-10 {
    margin-bottom: 40px;
  }

  .mb-20 {
    margin-bottom: 80px;
  }

  .my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }

  .justify-start {
    justify-content: start;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-space-evenly {
    justify-content: space-evenly;
  }

  .spacer {
    flex: 1 1 auto;
  }

  .pr-1 {
    padding-right: 4px;
  }

  .pr-2 {
    padding-right: 8px;
  }

  .pr-4 {
    padding-right: 16px;
  }

  .pr-5 {
    padding-right: 20px;
  }

  .pr-6 {
    padding-right: 24px;
  }

  .pl-2 {
    padding-left: 8px;
  }

  .pl-4 {
    padding-left: 16px;
  }

  .pl-5 {
    padding-left: 20px;
  }

  .pl-6 {
    padding-left: 24px;
  }

  .pl-7 {
    padding-left: 28px;
  }

  .pl-8 {
    padding-left: 32px;
  }


  .pl-10 {
    padding-left: 40px;
  }

  .pt-6 {
    padding-top: 24px;
  }

  .pt-2 {
    padding-top: 8px;
  }

  .pt-3 {
    margin-top: 12px;
  }

  .pt-4 {
    padding-top: 16px;
  }

  .pt-5 {
    padding-top: 20px;
  }

  .pb-2 {
    padding-bottom: 8px;
  }

  .pb-4 {
    padding-bottom: 16px;
  }

  .pb-6 {
    padding-bottom: 24px;
  }

  .pb-8 {
    padding-bottom: 32px;
  }

  .pb-12 {
    padding-bottom: 48px;
  }

  .pb-3 {
    padding-bottom: 12px;
  }

  .px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }

  .py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .pxy-1 {
    padding: 4px;
  }

  .pxy-1-2 {
    padding: 4px 8px;
  }

  .pxy-2 {
    padding: 8px;
  }

  .pxy-3 {
    padding: 12px;
  }

  .pxy-4 {
    padding: 16px;
  }

  .pxy-6 {
    padding: 24px;
  }

  .pxy-12 {
    padding: 48px;
  }

  .pxy-16 {
    padding: 64px;
  }

  .pxy-2-4 {
    padding: 8px 16px;
  }

  .pxy-3-4 {
    padding: 12px 16px;
  }

  .pxy-3-8 {
    padding: 12px 24px;
  }

  .pxy-4-2 {
    padding: 16px 8px;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-20 {
    font-size: 20px;
  }

  .font-22 {
    font-size: 22px;
  }

  .font-24 {
    font-size: 24px;
  }

  .font-32 {
    font-size: 32px;
  }

  .font-40 {
    font-size: 40px;
  }

  .font-60 {
    font-size: 60px;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .fixed {
    position: fixed;
  }

  .m-auto {
    margin: auto;
  }

  .h-full {
    height: 100%;
  }

  .h-auto {
    height: auto;
  }

  .h-fit-content {
    height: fit-content;
  }

  .w-50 {
    width: 50%;
  }

  .w-75 {
    width: 75%;
  }

  .w-full {
    width: 100%;
  }

  .cursor * {
    cursor: pointer;
  }

  .cursor {
    cursor: pointer;
  }

  .cursor-disable {
    cursor: not-allowed !important;
  }

  .move-cursor {
    cursor: move;
  }

  .menu {
    border-radius: 8px;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.1);
    user-select: none;
  }

  .no-select {
    user-select: none;
  }

  .text-wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 40px;
    overflow: hidden;
  }

  .nowrap {
    white-space: nowrap;
  }

  .flex-w-wrap {
    flex-wrap: wrap;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .overflow-auto {
    overflow: auto;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-10 {
    font-size: 10px;
  }

  .font-20 {
    font-size: 20px;
  }

  .green-positive-text{
    color: ${({ theme }) => theme.colors.ZenPositive};
  }

  .grey-text {
    color: ${({ theme }) => theme.colors.ZenGray};
  }

  .zen-default-text {
    color: ${({ theme }) => theme.colors.ZenDefault};
  }

  .grey-secondary-text {
    color: ${({ theme }) => theme.colors.ZenGraySecondary};
  }

  .white-text{
    color: ${({ theme }) => theme.colors.white};

  }

  .search-border {
    border: 1px solid #d2e5ed;
    border-radius: 8px;
  }

  .o05 {
    opacity: 0.5;
  }

  .o07 {
    opacity: 0.7;
  }

  .o1 {
    opacity: 1;
  }

  .o03 {
    opacity: 0.3;
  }

  .radius-1 {
    border-radius: 4px;
  }

  .radius-2 {
    border-radius: 8px;
  }

  .radius-3 {
    border-radius: 12px;
  }

  .radius-4 {
    border-radius: 16px;
  }

  .radius-6 {
    border-radius: 24px;
  }

  .self-start {
    align-self: flex-start;
  }

  .self-center {
    align-self: center;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-400 {
    font-weight: 400;
  }

  .border-top {
    border-top: 1px solid ${({ theme }) => theme.colors.border};
  }

  .border-left {
    border-left: 1px solid ${({ theme }) => theme.colors.border};
  }

  .border-right {
    border-right: 1px solid ${({ theme }) => theme.colors.border};
  }

  .border-bottom {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }

  .border-none {
    border: none;
  }

  .border {
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  }

  .gap-2 {
    gap: 8px;
  }

  .gap-3 {
    gap: 12px;
  }

  .gap-4 {
    gap: 16px;
  }

  .gap-6 {
    gap: 24px;
  }

  .gap-12 {
    gap: 48px;
  }

  .one-line {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .menu-text {
    max-width: 168px;
  }

  .fit-content {
    height: fit-content;
  }

  .popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(11, 22, 35, 0.49);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    .popup-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      background: #ffffff;
      border: 1px solid rgba(240, 246, 249, 0.5);
      box-sizing: border-box;
      box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
      border-radius: 16px;
      outline: 0;
      margin-top: 64px;
      padding: 48px;
      position: relative;
    }
  }

  .grab-cursor * {
    cursor: grabbing;
  }

  .grab-cursor {
    cursor: grabbing;
  }

  .move-cursor * {
    cursor: move;
  }

  .move-cursor {
    cursor: move;
  }

  .default-cursor * {
    cursor: default;
  }

  .default-cursor {
    cursor: default;
  }

  .divider {
    height: 1px;
    background: #DCDDE0;
    width: 100%;
  }

  * :focus-visible {
    outline: none;
  }

  * ::-webkit-scrollbar {
    width: 0;
    height: 0px;
  }

  * {
    scrollbar-width: none;
  }

  .two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fit-content {
    height: fit-content;
  }

  .password-mask {
    text-security: disc; /* IE/Safari */
    -moz-text-security: disc; /* FireFox */
    -webkit-text-security: disc; /* Chrome/Safari  */
  }

  .rotate-90 {
    transform: rotate(90deg);
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .rotate-270 {
    transform: rotate(270deg);
  }

  .roboto-bold{
    font-family: Roboto Bold;
  }

  .text-overflow-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break-word {
    word-break:break-word;
  }

  .z-index-10 {
    z-index:10;
  }

  .fixed-header {
    position: fixed;
    top: 0;
  }

  .session-code-container {
    min-height: 32px;
    background-color: white;
  }
`;
