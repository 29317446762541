import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getDataFromSessionData, getUserBillingAddress, getUserData, isJson } from '../../../helpers/utils';
import { createContact, createPaymentMethod, createSubscription } from '../../../store/features/formSlice';
import { addToast } from '../../../store/features/toastSlice';
import Button from '../button/button';

const StripePayment = ({
  backgroundColor,
  subscription,
  setPaymentDetails,
  onSubmitForm,
  selectedAddress,
  selectedBillingAddress,
  productComponentName,
  integration,
  api_key,
  showBillingAddress,
}) => {
  const { formSessionData, selectedFormPanel } = useSelector(state => state.forms);
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const Idempotency_Key = useRef({
    contacts: {
      key: uuidv4(),
    },
    paymentMethods: {
      key: uuidv4(),
    },
    subscriptions: {
      key: uuidv4(),
    },
  });

  const [message, setMessage] = useState(null);
  const [paymentConfirmationMessage, setPaymentConfirmationMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUserStripeBillingDetails = () => {
    return {
      name: `${getDataFromSessionData(formSessionData, selectedAddress, 'contact.forename')} ${getDataFromSessionData(
        formSessionData,
        selectedAddress,
        'contact.surname',
      )}`,
      email: getDataFromSessionData(formSessionData, selectedAddress, 'contact.email'),
      phone: getDataFromSessionData(formSessionData, selectedAddress, 'contact.mobile'),
      address: {
        country:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'country_code') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.country_code'),
        city:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'city') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.city') ||
          '',
        state:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'state') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.state') ||
          '',
        line1:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'line1') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line1') ||
          '',
        line2:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'line2') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line2') ||
          '',
        postal_code:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'postcode') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.postcode') ||
          '',
      },
    };
  };

  const onSubmit = async () => {
    if (!api_key) {
      dispatch(addToast({ error: true, text: 'There is no api key available.' }));
      return;
    }
    if (!subscription) {
      dispatch(addToast({ error: true, text: `Please select ${productComponentName}` }));
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error: submitError } = await elements.submit();

    if (submitError) {
      setIsLoading(false);
      return;
    }

    let contactData = null;
    let subs = null;

    try {
      setMessage(null);

      const { contacts, paymentMethods, subscriptions } = Idempotency_Key.current;

      contactData = await dispatch(
        createContact({
          request: {
            ...getUserData(formSessionData, selectedAddress, selectedBillingAddress, showBillingAddress),
            integration: { id: integration.id },
          },
          api_key: api_key,
          Idempotency_Key: contacts.key,
        }),
      );

      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: getUserStripeBillingDetails(),
        },
      });

      const data = await dispatch(
        createPaymentMethod({
          request: {
            external_reference: paymentMethod.id,
            integration: { id: integration.id },
            is_default: true,
          },
          api_key: api_key,
          contact_id: contactData.id,
          Idempotency_Key: paymentMethods.key,
        }),
      );

      subs = await dispatch(
        createSubscription({
          request: {
            id: subscription?.value,
            payment_method: { id: data.external_reference },
            product: { id: subscription.product_id },
            price: { id: subscription.price_id },
            integration: { id: integration.id },
            vendor: null,
          },
          api_key: api_key,
          contact_id: contactData.id,
          Idempotency_Key: subscriptions.key,
        }),
      );

      const client_secret = subs.client_secret;

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret: client_secret,
        confirmParams: {
          return_url: `${window.location.protocol + '//' + window.location.host}/#/forms`,
        },
        redirect: 'if_required',
      });

      if (paymentIntent || error?.payment_intent?.status === 'succeeded') {
        setPaymentConfirmationMessage('Your payment is completed');
        setIsLoading(false);
        if (selectedFormPanel?.submit_onpayment) {
          onSubmitForm(contactData.id, subs?.external_reference, subs?.id);
        } else {
          setPaymentDetails({
            isCollectPayment: true,
            isConfirmed: true,
            contactId: contactData.id,
            external_reference: subs?.external_reference,
            subscription_id: subs?.id,
          });
        }
      } else if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          error?.decline_code === 'generic_decline'
            ? setMessage(t('WE_DO_NOT_ACCEPT_PREPAID_CARDS'))
            : setMessage(error.message);
          dispatch(
            addToast({
              error: true,
              text: error?.decline_code === 'generic_decline' ? t('WE_DO_NOT_ACCEPT_PREPAID_CARDS') : error.message,
            }),
          );
        } else {
          setMessage('An unexpected error occurred.');
        }
        setIsLoading(false);
      }
    } catch (e) {
      const errorResponse = e?.response?.data?.response || {};
      let jsonResponse = null;
      jsonResponse = isJson(errorResponse) ? JSON.parse(errorResponse) : errorResponse;
      jsonResponse = isJson(jsonResponse?.response) ? JSON.parse(jsonResponse?.response) : jsonResponse?.response;

      const errorText =
        jsonResponse?.error_description || e?.response?.data?.error_description || 'An unexpected error occurred.';
      dispatch(addToast({ error: true, text: errorText }));

      setMessage(errorText);
      setIsLoading(false);

      if (errorText === 'Matching subscription already exists for the contact') {
        onSubmitForm(contactData.id, subs?.external_reference, subs?.id);
      }
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: {
        address: {
          country: selectedAddress?.country_code ? 'never' : 'auto',
        },
      },
    },
    defaultValues: {
      billingDetails: getUserStripeBillingDetails(),
    },
  };

  return (
    <div className="w-full">
      <label className="regular-text lighter-text">Enter payment details</label>
      <form className="mt-4 justify-center items-center">
        <PaymentElement options={paymentElementOptions} />
        <Button
          label={`Donate ${subscription?.priceAmountLabel ? subscription.priceAmountLabel : ''}`}
          className={`w-full mt-6 ${(isLoading || !stripe || !elements) && 'disabled'}`}
          disabled={isLoading || !stripe || !elements}
          onClick={onSubmit}
          size="large"
          borderRadius="16px"
          borderColor={backgroundColor || '#8927EF'}
          bgColor={backgroundColor || '#8927EF'}
          color="#ffffff"
          width="100%"
        />
        {message && <div className="regular-text error-text mt-4">{message}</div>}
        {paymentConfirmationMessage && (
          <div className="regular-text green-positive-text mt-4">{paymentConfirmationMessage}</div>
        )}
      </form>
    </div>
  );
};

const StripeWrapper = ({
  backgroundColor = '',
  subscription,
  setPaymentDetails,
  onSubmitForm,
  selectedAddress,
  productComponentName,
  integration,
  api_key,
  font,
  fontFamily,
  selectedBillingAddress,
  showBillingAddress,
}) => {
  const stripePromise = loadStripe(integration.public_key);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: backgroundColor || '#8927EF',
      colorBackground: '#ffffff',
      colorText: '#16192C',
      spacingUnit: '4px',
      borderRadius: '16px',
      fontFamily: fontFamily?.fontFamily || 'inherit',
    },
  };

  const option = {
    mode: 'subscription',
    amount: subscription?.amount || 1000,
    currency: subscription?.currency?.toLowerCase() || 'usd',
    paymentMethodCreation: 'manual',
    setupFutureUsage: 'off_session',
    appearance,
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
      },
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap',
      },
    ],
  };

  return (
    <Elements stripe={stripePromise} options={option}>
      <StripePayment
        backgroundColor={backgroundColor}
        subscription={subscription}
        setPaymentDetails={setPaymentDetails}
        onSubmitForm={onSubmitForm}
        selectedAddress={selectedAddress}
        selectedBillingAddress={selectedBillingAddress}
        productComponentName={productComponentName}
        integration={integration}
        api_key={api_key}
        showBillingAddress={showBillingAddress}
      />
    </Elements>
  );
};

export default StripeWrapper;
